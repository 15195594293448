<template>
  <v-card class="pa-2">
    <v-card-title class="d-flex justify-space-between">
      <span>Configuração</span>
      <v-btn color="error" @click="clear">Limpar</v-btn>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-treeview
        v-model="optionsSelected"
        selectable
        :items="options"
        :search="search"
      ></v-treeview>
      <span class="error--text"
        >*Somente os itens selecionados aparecerão na tela de filtro</span
      >
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn plain @click="close">Cancelar</v-btn>
      <v-btn color="primary" class="px-4" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

import { data } from './_data.js'

import { LOGGING } from '@/utils/storages'

const LOCAL_STORAGE_ID = LOGGING

export default {
  name: 'ListFiltroConfig',
  data,
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),

    clear() {
      this.optionsSelected.splice(0)
      localStorage.setItem(LOCAL_STORAGE_ID.options, JSON.stringify([]))
      this.successMessage('Configuração reiniciada com sucesso!')
    },
    save() {
      localStorage.setItem(
        LOCAL_STORAGE_ID.options,
        JSON.stringify(this.optionsSelected)
      )
      this.close()
      this.successMessage('Configuração salva com sucesso!')
    },
    close() {
      this.$emit('close')
    },
  },
  created() {
    this.optionsSelected =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID.options)) || []
  },
}
</script>
