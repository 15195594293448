<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div class="mt-4">
      <v-card-title v-if="canList"> Filtro </v-card-title>
      <v-data-table
        :headers="headersComputed"
        :items="items"
        :search="search"
        :server-items-length="logs.count"
        item-key="public_id"
        class="border"
        :footer-props="{
          itemsPerPageAllText: `Todos (${logs.count})`,
          itemsPerPageOptions: [pageSize].concat(
            [15, 50, 100, 500, 1000, 2000].filter((el) => el != pageSize)
          ),
        }"
        :items-per-page="pageSize"
        :options.sync="options"
        @update:page="$emit('handleGetLogs', $event)"
        @update:items-per-page="$emit('handleGetLogs', $event)"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Logs registradas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon
              v-if="canList"
              medium
              @click="handleGetLogs"
              title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog_indicadores" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mr-2 mb-2" v-on="on"
                  >Indicadores</v-btn
                >
              </template>
              <Indicadores :headers="headers" @close="close_indicadores" />
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="canList"
            title="Ver Log"
            small
            class="mx-1"
            @click="showItem(item)"
          >
            mdi-eye
          </v-icon>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{
            item.created_at ? new Date(item.created_at).toLocaleString() : '-'
          }}
        </template>
        <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import { BasicPermissions, Sequences } from '@/utils/permissions'
import LogsApi from '@/services/gerencial/logging'
import Indicadores from '@/components/gerencial/logging/listagem/indicadores/Componente'
import { LOGGING, setIndicadores } from '@/utils/storages'
const LOCAL_STORAGE_ID = LOGGING

export default {
  name: 'ListarLogs',
  components: { Indicadores },
  data() {
    return {
      search: '',
      logs: {},
      options: {},
      pageSize: 10,
      dialog_indicadores: false,
      loading: false,
      headers: [
        { text: 'Ações', value: 'actions', habilited: true, block: true },
        {
          text: 'Public Id',
          align: 'start',
          value: 'public_id',
          habilited: true,
          block: false,
        },
        { text: 'Url', value: 'url', habilited: true, block: false },
        { text: 'Path', value: 'path', habilited: true, block: true },
        {
          text: 'Query Params',
          value: 'query_params',
          habilited: true,
          block: false,
        },
        { text: 'Method', value: 'method', habilited: true, block: false },
        {
          text: 'Client Ip',
          value: 'client_ip_address',
          habilited: true,
          block: false,
        },
        {
          text: 'Status Code',
          value: 'status_code',
          habilited: true,
          block: false,
        },
        {
          text: 'Tempo de execução',
          value: 'execution_time',
          habilited: true,
          block: false,
        },
        { text: 'Usuario', value: 'user.email', habilited: true, block: false },
        {
          text: 'Acessado em',
          value: 'created_at',
          habilited: true,
          block: true,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),

    ...mapState('params', [LOGGING.storage]),
    params() {
      return this[LOGGING.storage]
    },
    // permission() {
    //   return Sequences.Logging.toString()
    // },

    canList() {
      return true
      //   return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    // canCreate() {
    //   return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    // },
    // canUpdate() {
    //   return this.$canDo(BasicPermissions.EDITAR, this.permission)
    // },
    // canDelete() {
    //   return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    // },

    items() {
      return 'results' in this.logs ? this.logs.results : []
    },

    headersComputed() {
      return this.headers
        .filter((item) => item.display !== false)
        .filter((header) => !!header.habilited)
    },
  },

  async created() {
    this.headers = setIndicadores(LOCAL_STORAGE_ID, this.headers)
    if (this.canList) {
      await this.handleGetLogs()
    }
  },

  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    close_indicadores() {
      this.dialog_indicadores = false
    },
    async showItem(item) {
      this.$emit('showLog', item)
    },
    async handleGetLogs(loading = true) {
      if (this.empresaAtual.system_admin) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const page_size = itemsPerPage
        this.page_size = page_size
        this.loading = loading
        try {
          //const { sortBy, sortDesc } = this.options
          let ordering
          if (
            sortBy &&
            sortBy.length === 1 &&
            sortDesc &&
            sortDesc.length === 1
          ) {
            ordering = this.options.sortBy[0]
            ordering = this.options.sortDesc[0] ? '-' + ordering : ordering
            ordering = ordering.replaceAll('.', '__')
          }
          this.logs = await LogsApi.getLogs({
            params: this.params,
            page_size: page_size > 0 ? page_size : 10,
            page: page,
            ordering: ordering,
          })
        } catch (error) {
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 403)
          ) {
            this.errorMessage('Usuário não autorizado.')
          } else {
            this.errorMessage('Ocorreu algum erro inesperado.')
          }
        } finally {
          this.loading = false
        }
      } else {
        this.errorMessage(
          'Empresa atual não possui permissão para listar logs .'
        )
      }
    },
  },
}
</script>
