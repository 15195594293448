export const data = () => ({
  search: '',
  optionsSelected: [],
  options: [
    {
      id: 10,
      name: 'Data',
      children: [
        {
          id: 'data_inicio',
          name: 'De',
        },
        {
          id: 'data_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 20,
      name: 'Campos',
      children: [
        {
          id: 'path',
          name: 'Path',
        },
        {
          id: 'user',
          name: 'Usuário',
        },
      ],
    },
  ],
})
