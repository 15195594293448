import axios from 'axios'

class LogApi {
  async getLogs(params) {
    try {
      const { data } = await axios.get(`api/v1/integracao/logs/`, params)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getLog(public_id) {
    console.log(public_id)
    try {
      const { data } = await axios.get(`api/v1/integracao/logs/${public_id}/`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new LogApi()
