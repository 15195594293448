import { LOGGING } from '@/utils/storages'
const LOCAL_STORAGE_ID = LOGGING

export const computed = {
  showData() {
    return this.params.data_inicio?.visible || this.params.data_fim?.visible
  },
  showFields() {
    return this.params.path.visible || this.params.user.visible
  },
  areSettingsEmpty() {
    return !(this.showData || this.showFields)
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID.options)) || []
  },
}
