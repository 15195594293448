var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"mt-4"},[(_vm.canList)?_c('v-card-title',[_vm._v(" Filtro ")]):_vm._e(),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headersComputed,"items":_vm.items,"search":_vm.search,"server-items-length":_vm.logs.count,"item-key":"public_id","footer-props":{
        itemsPerPageAllText: ("Todos (" + (_vm.logs.count) + ")"),
        itemsPerPageOptions: [_vm.pageSize].concat(
          [15, 50, 100, 500, 1000, 2000].filter(function (el) { return el != _vm.pageSize; })
        ),
      },"items-per-page":_vm.pageSize,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.$emit('handleGetLogs', $event)},"update:items-per-page":function($event){return _vm.$emit('handleGetLogs', $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Logs registradas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"medium":"","title":"Atualizar"},on:{"click":_vm.handleGetLogs}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Indicadores")])]}}]),model:{value:(_vm.dialog_indicadores),callback:function ($$v) {_vm.dialog_indicadores=$$v},expression:"dialog_indicadores"}},[_c('Indicadores',{attrs:{"headers":_vm.headers},on:{"close":_vm.close_indicadores}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.canList)?_c('v-icon',{staticClass:"mx-1",attrs:{"title":"Ver Log","small":""},on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v(" mdi-eye ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at ? new Date(item.created_at).toLocaleString() : '-')+" ")]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }