export function data() {
  return {
    settingFilters: false,
    dependents: {},
    gates: [],
    params: {
      data_inicio: {
        value: '',
        visible: false,
      },
      data_fim: {
        value: '',
        visible: false,
        until: true,
      },
      path: {
        value: '',
        visible: false,
      },
      user: {
        value: '',
        visible: false,
      },
    },
    allOptions: ['data_inicio', 'data_fim', 'path', 'user'],
  }
}
