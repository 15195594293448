<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <ListFiltro @search="handleGet" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Logs">
          <ListarLogs @showLog="showLog" ref="listagem" />
        </BaseCard>
      </v-col>
    </v-row>

    <div>
      <v-dialog persistent hide-overlay v-model="show_log">
        <ShowLog :item="log" @close="close" />
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import ListarLogs from '@/components/gerencial/logging/listagem/Componente.vue'
import ShowLog from '@/components/gerencial/logging/listagem/Show.vue'
import LogsApi from '@/services/gerencial/logging'
import ListFiltro from '@/components/gerencial/logging/listagem/filtros/Componente'

export default {
  data() {
    return {
      log: {},
      show_log: false,
      page: {
        title: 'Listagem de logging',
      },
      breadcrumbs: [
        {
          text: 'Gerencial',
          disabled: true,
        },
        {
          text: 'Logging',
          disabled: true,
        },
      ],
    }
  },
  components: {
    ListarLogs,
    ShowLog,
    ListFiltro,
  },
  methods: {
    async handleGet() {
      await this.$refs['listagem'].handleGetLogs()
    },
    async showLog(item) {
      const log = await LogsApi.getLog(item.public_id)
      this.log = log
      this.show_log = true
    },
    close() {
      this.log = { user: {} }
      this.show_log = false
    },
  },
}
</script>

<style></style>
